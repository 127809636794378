import React, { Component } from "react";
import logo from "./picture/logo1.jpg"
import lp from "./picture/lp.png"
import lp1 from "./picture/lp1.gif"
import pwc from "./picture/pwc.jpg";
import pwcbp from "./picture/pwbp.jpg";

class AboutUs extends Component {
  render() {
    return (
      <section id="aboutUs">
      <div>
        <h2>ประวัติความเป็นมา</h2>
        <img src={logo} width="15%"/>
        <p>
          พันธ์ทวี อ.บ้านไผ่ จ.ขอนแก่นจากจุดเริ่มต้น เคมีเพื่อการเกษตร,ปุ๋ย และเมล็ดพันธุ์ ได้ขยายกลุ่มสินค้าสู่ <br/> อาหารสัตว์,อุปกรณ์ระบบน้ำเพื่อการเกษตร,อุปกรณ์ช่าง และสินค้าอุปโภค บริโภคครบวงจร         
        </p>
        <img src={pwcbp} width="40%"></img><br /><br />
        <img src={pwc} width="50%"></img><br />
              
        
    </div>    
      </section>
    );
  }
}

export default AboutUs;
