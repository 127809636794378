import React, { Component } from "react";
import picture from "./picture/pwc.jpg";
import callcenter from "./picture/callcenter.png";
import EmailIcon from "@material-ui/icons/Email";
import { IoLogoFacebook } from "react-icons/io";

class Contact extends Component {
  render() {
    return (
      <section id="contact">
        <div class="container" style={{ color: "white" }}>
          <div class="col-12">
            <div class="row">
              <div class="col-4">
                <br />
                <h5>บริษัท พันธ์ทวี คอร์ปอเรชั่น จำกัด</h5>
                <h5 style={{fontSize:"40px",cursor:"pointer"}} onClick={() =>
                  window.open("https://www.facebook.com/phantaweemall/", "_blank")
                }><IoLogoFacebook /> </h5> 
              </div>
              <div class="col-4 border-left">
                <br />
                <h5>สำนักงานใหญ่ บริษัทพันธ์ทวีมอลล์</h5>
                <h6>183 หมู่4 ต.บึงเนียม อ.เมือง จ.ขอนแก่น</h6>
              </div>
              <div class="col-4 border-left">
                <br />
                <h5>บริการลูกค้าสัมพันธ์</h5>
                <h6>043-001788-798</h6>                
                <h6>pwcmall.onlinesales@gmail.com</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Contact;
