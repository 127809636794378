import React, { Component } from "react";

class Home extends Component {
  render() {
    return (
      <section class="col-md-12 col-xl-12 container" id="home">
        <div class="col-12 col-md-12 col-xl-12" style={{ textAlign: "center" }}>
          <h2>พันธ์ทวีมอลล์</h2>
          <p style={{ paddingTop: "80px" }}>
            <h3> เพื่อนแท้เกษตรกรไทย(Your Farmer's Soulmate)</h3>
          </p>
          
        </div>
      </section>
    );
  }
}

export default Home;
