import React, { Component } from "react";
import shopee from "./picture/Shopee-logo.jpg";
import lnwshop from "./picture/lnwshop-logo.png";
class Product extends Component {
  render() {
    return (
      <section id="product" style={{ textAlign: "center" }}>
        <div>
          <h2 style={{ textAlign: "center" }}>ช่องทางการจัดจำหน่าย</h2>
        </div>
        <div class="row">
          <div class="col-6 shadow-lg p-3 mb-5 bg-white rounded">
            <a href="#">
              <img
                src={shopee}
                style={{ width: "50%", borderRadius: "50%" }}
                onClick={() =>
                  window.open("https://shopee.co.th/pwc_mall", "_blank")
                }
              ></img>
            </a>
          </div>
          <div class="col-6 shadow-lg p-3 mb-5 bg-white rounded ">
            <a href="#">
              <img
                src={lnwshop}
                style={{ borderRadius: "50px" }}
                onClick={() =>
                  window.open("http://pwcmall.lnwshop.com/", "_blank")
                }
              ></img>
            </a>
          </div>
        </div>
      </section>
    );
  }
}

export default Product;
