import React, { Component } from "react";
import { HashRouter, NavLink, Route } from "react-router-dom";
import Home from "./Home";
import AboutUs from "./AboutUs";
import Contacts from "./Contacts";
import logo from "./picture/pwclogo.png";
import AnchorLink from "react-anchor-link-smooth-scroll";
import News from "./News";
import Product from "./Product";
import pwc from "./picture/pwc.jpg";
import lp2 from "./picture/lp2.jpg";
import Vision from "./Vision";

class Main extends Component {
  render() {
    return (
      <HashRouter>
        <div
          class="container"
          style={{
            paddingBottom: "10px",
            backgroundColor: "white",
            position: "sticky",
            top: "0px",
            zIndex: "99999",
          }}
        >
          <div class="row">
            <div
              class="col-3"
              width="fit-content"
              style={{ textAlign: "right" }}
            >
              <img src={logo} style={{ width: "100%" }}></img>
            </div>
            <div class="col-9" textAlign="right">
              <ul className="header " style={{ textAlign: "right" }}>
                <li>
                  <AnchorLink offset="155" href="#home">
                    หน้าแรก
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink offset="155" href="#aboutUs">
                    เกี่ยวกับเรา
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink offset="155" href="#viSion">
                    วิสัยทัศน์
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink offset="155" href="#News">
                    ข่าวสาร
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink offset="155" href="#product">
                    ช่องทางการจำหน่าย
                  </AnchorLink>
                </li>
                <li>
                  <AnchorLink offset="155" href="#contact">
                    ติดต่อเรา
                  </AnchorLink>
                </li>
                <li>
                  <a
                    offset="155"
                    href="#"
                    onClick={() =>
                      window.open("http://webmail.phantaweemall.com/Mondo/lang/sys/login.aspx", "_blank")
                    }
                  >
                    Email
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="w3-container w3-center w3-animate-zoom container"
          style={{
            backgroundImage: `url(${lp2})`,
            width: "100%",
            height: "700px",
          }}
        >
          <Route exact path="/" component={Home} />
        </div>
        <div
          class="shadow-lg p-3 mb-5 bg-white rounded container"
          style={{ marginTop: "20px", textAlign: "center" }}
        >
          <AboutUs />
        </div>
        <div
          class="shadow-lg p-3 mb-5  rounded container"
          style={{ marginTop: "20px" }}
        >
          <Vision />
        </div>
        <div
          class="shadow-lg p-3 mb-5  rounded container"
          style={{ marginTop: "20px", backgroundColor: "#7fb539" }}
        >
          <News />
        </div>
        <div
          class="shadow-lg p-3 mb-5 bg-white rounded container"
          spacig
          style={{ marginTop: "20px", backgroundColor: "gray" }}
        >
          <Product />
        </div>
        <div
          class="shadow-lg p-3 mb-5  rounded container"
          spacig
          style={{ marginTop: "20px", backgroundColor: "#7fb539" }}
        >
          <Contacts />
        </div>
      </HashRouter>
    );
  }
}

export default Main;
