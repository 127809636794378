import React, { Component } from "react";


class Vision extends Component {
  render() {
    return (
      <section id="viSion">
          
    
    <div class="card">
        <h2 style={{textAlign:"center"}}>
        วิสัยทัศน์
      </h2>
      <h4 style={{textAlign:"center"}}>เพื่อนแท้เกษตรกรไทย (Your Farmer's Soulmate)</h4>
      <p >คือ วิสัยทัศน์ที่ท้าทายให้ พันธ์ทวี ต้องก้าวไปอย่างมั่นคง และสร้างเครือข่ายการบริการครอบคลุม ผ่านเครือข่ายผู้ประกอบการขนาดกลางและผู้ประกอบการรายย่อย พันธ์ทวีพร้อมที่จะเป็นผู้ต่อเติมโครงสร้าง ผลักดัน และสร้างความแข็งแกร่งจากฐานล่าง คือ ภาคเกษตรกรรม สู่เศรษฐกิจและสังคมในภาคอื่นๆ นั่นคือพันธกิจที่สร้างความภาคภูมิใจแก่พวกเรา</p>
      <img src="http://touchscreentogo.phantaweemall.com/img/news2.png"
           />
           </div>
      <h2 style={{textAlign:"center"}} >
        พันธกิจ
      </h2>
      <h4 >P = Profressional มีความเชี่ยวชาญเฉพาะทาง</h4>
      <h4 >W = Worthy ราคาที่คุ้มค่า</h4>
      <h4 >C = Cozy อบอุ่น เป็นกันเอง</h4>
      <p >พันธ์ทวี มอลล์ กำลังเตรียมพร้อมที่จะก้าวเข้าสู่การจดทะเบียนในตลาดหลักทรัพย์ในอนาคตเพื่อขยายกิจการ และตอบสนองความต้องการของเกษตรกรให้ครอบคลุมพื้นที่ได้อย่างกว้างขวางทั่วทุกภาคของประเทศและยังก้าวต่อไปอย่างไม่หยุดยั้งด้วยการแสวงหาพันธมิตร เพื่อร่วมมือกันในการให้บริการแก่ลูกค้าที่ครบวงจรมากขึ้น</p>
      <p >ทีมบริหารและพนักงานพันธ์ทวีทุกคนมีความยินดีอย่างยิ่งที่จะเป็นผู้ให้บริการ และรับใช้ลูกค้าทุกท่าน รวมถึงลูกค้าชาวเกษตรกร ซึ่งเป็นกระดูกสันหลังของประเทศ ด้วยความมุ่งมั่น และจะพัฒนาการบริการนี้ให้ตอบสนองความพึงพอใจของลูกค้าสืบไป</p>
      <p >
        ด้วยความขอบคุณ<br />
        ทีมงาน พันธ์ทวี มอลล์
      </p>
      <div class="card" >
      <h2 style={{textAlign:"center"}}>
        พันธ์ทวีสาขาขอนแก่น ยกระดับสู่ความทันสมัย
      </h2>
      <h4 >ด้วยความมุ่งมั่นที่จะตอบสนองความต้องการของลูกค้า</h4>
      <p >พันธ์ทวีสาขาขอนแก่น กำลังปรับปรุง ยกระดับหน้าร้านและคลังเก็บสินค้าใหม่ เพื่อเพิ่มความทันสมัยความครบวงจร เพิ่มพื้นที่รองรับลูกค้าที่เพิ่มขึ้น และเพิ่มสินค้าที่หลากหลายให้ตอบโจทย์กับท่านลูกค้า รวมถึงพัฒนาการบริการให้ตอบสนองความพึงพอใจของลูกค้าสืบไป</p>
      <p >พันธ์ทวียังคงความเชี่ยวชาญ และการเป็นผู้นำตลาดด้านสินค้าพื้นฐานเพื่อการเกษตร พันธ์ทวียินดีอย่างยิ่งที่จะยกระดับการบริการแก่ลูกค้าที่ครบวงจรมากขึ้น และมุ่งมั่นเดินตามวิสัยทัศน์ของพันธ์ทวี คือ เพื่อนแท้เกษตรกรไทย</p>
      <img src="http://touchscreentogo.phantaweemall.com/img/news1.png"
           />

    
    </div>
      </section>
    );
  }
}

export default Vision;