import React, { Component } from "react";
import pic from "./picture/ตู้bugmee.jpeg";
import pic1 from "./picture/pwcกุมภวา.jpg"
import kerry from "./picture/kerry.jpg";
import zonef from "./picture/zonef.png";

class News extends Component {
  render() {
    return (
      <section id="News">
        <div style={{ marginBottom: "20px"}}>
          <h2 style={{textAlign:"center"}}>ข่าวสาร</h2>
        </div>
        <div class="container" style={{paddingBottom:"50px"}}>
          <div class="row">
            <div
              class="card col-3"
              style={{ height: "350px", marginRight: "120px",padding:"0px" }}            >
              <img src={zonef} class="card-img-top" style={{width:"100%",height:"50%"}} />
              <div class="card-body" style={{padding:"0px"}}>
              <h5 class="card-title" style={{backgroundColor:"#7fb539",height:"30px",color:"white",width:"50%"}}>พันธ์ทวีโฉมใหม่</h5>
                <p class="card-text" style={{padding:"5px"}}>
                พันธ์ทวีเปิดโซนใหม่...พร้อมให้บริการแล้ววันนี้ ที่พันธ์ทวี
                </p>
              </div>
            </div>
            <div
              class="card col-3 col-md-3 col-xl-3" style={{ height: "350px", marginRight: "120px",padding:"0px"}}
            >
              <img src={pic} class="card-img-top" style={{width:"100%",height:"50%"}} />
              <div class="card-body" style={{padding:"0px"}}>
                <h5 class="card-title" style={{backgroundColor:"#7fb539",height:"30px",color:"white",width:"50%"}}>ตู้Bug mee</h5>
                <p class="card-text" style={{padding:"5px"}}>
                    พันธ์ทวีสำนักงานใหญ่นำร่อง
                  ใช้งานตู้สั่งซื้อสินค้าโดยระบบสัมผัส แล้วพบกันเร็วๆนี้......
                  ที่ พันธ์ทวีสำนักงานใหญ่
                </p>
              </div>
            </div>
            <div class="card col-3 col-md-3 col-xl-3" style={{ height: "350px",padding:"0px"}}>
            <img src={kerry} class="card-img-top" style={{width:"100%",height:"50%"}} />
              <div class="card-body" style={{padding:"0px"}}>
              <h5 class="card-title" style={{backgroundColor:"#7fb539",height:"30px",color:"white",width:"50%"}}>Kerry เปิดแล้ว</h5>
                <p class="card-text" style={{padding:"5px"}}>
                พันธ์ทวีเราเป็นตัวแทนในการจัดส่งพัสดุกับ Kerry ส่งของได้ทั่วประเทศแล้ววันนี้ที่..พันธ์ทวีสำนักงานใหญ่(โซนเปิดใหม่)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default News;
